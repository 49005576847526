h1 { font-size: 2em; margin: 0.67em 0; color: #353535; }
h2 { font-size: 1.5em; margin: 0.83em 0; }
h3 { font-size: 1.17em; margin: 1em 0; }
h4 { font-size: 1em; margin: 1.33em 0; }
h5 { font-size: 0.83em; margin: 1.67em 0; }
h6 { font-size: 0.75em; margin: 2.33em 0; }
/* * Addresses styling not present in IE 7/8/9, Safari 5, and Chrome. */
abbr[title] { border-bottom: 1px dotted; }
/* * Addresses style set to `bolder` in Firefox 3+, Safari 4/5, and Chrome. */
b, strong { font-weight: bold; }
blockquote { margin: 1em 40px; }
/* * Addresses styling not present in Safari 5 and Chrome. */
dfn { font-style: italic; }
/* * Addresses styling not present in IE 6/7/8/9. */
mark { background: #ff0; color: #000; }
/* * Addresses margins set differently in IE 6/7. */
p, pre { margin: 1em 0; }
/* * Corrects font family set oddly in IE 6, Safari 4/5, and Chrome. */
code, kbd, pre, samp { font-family: monospace, serif; _font-family: 'courier new', monospace; font-size: 1em; }
/* * Improves readability of pre-formatted text in all browsers. */
pre { white-space: pre; white-space: pre-wrap; word-wrap: break-word; }
/* * Addresses CSS quotes not supported in IE 6/7. */
q { quotes: none; }
/* * Addresses `quotes` property not supported in Safari 4. */
q:before, q:after { content: ''; content: none; }
small { font-size: 75%; }
/* * Prevents `sub` and `sup` affecting `line-height` in all browsers. */
sub, sup { font-size: 75%; line-height: 0; position: relative; vertical-align: baseline; }
sup { top: -0.5em; }
sub { bottom: -0.25em; }\
/* ==========================================================================
Lists
========================================================================== */
/* * Addresses margins set differently in IE 6/7. */

dl, menu, ol, ul { margin: 1em 0; }
dd { margin: 0 0 0 40px; }
/* * Addresses paddings set differently in IE 6/7. */
menu, ol, ul { padding: 0 0 0 40px; }
/* * Corrects list images handled incorrectly in IE 7. */
nav ul, nav ol { list-style: none; list-style-image: none; }
/* ==========================================================================
Embedded content
========================================================================== */
/* * 1. Removes border when inside `a` element in IE 6/7/8/9 and Firefox 3.
* 2. Improves image quality when scaled in IE 7. */

img { border: 0; /* 1 */
-ms-interpolation-mode: bicubic; /* 2 */ }
.effects { padding-left: 15px;  margin-bottom: 30px;}
.effects .img { position: relative; margin-bottom: 10px; position: relative; z-index: 1; overflow: hidden; }
.effects .img:nth-child(n) { margin-right: 5px; }
.effects .img:first-child { margin-left: -15px; }
.effects .img:last-child { margin-right: 0; }
.effects .img img { display: block; margin: 0; padding: 0; height: auto; }
.overlay { display: block; position: absolute; z-index: 20; background: rgba(100, 194, 158, 0.8); overflow: hidden;}
a.close-overlay { display: block; position: absolute; top: 0; right: 0; z-index: 100; width: 45px; height: 45px; font-size: 20px; font-weight: 700; color: #fff; line-height: 45px; text-align: center; background-color: #000; cursor: pointer; }
a.close-overlay.hidden { display: none; }
a.expand { display: block; position: absolute; z-index: 100; text-align: center; color: #fff; font-weight: 600; font-size: 14px; text-transform: uppercase; line-height: 30px; }
.overlay-effect .img i { font-size: 30px; }
.overlay-effect .overlay { bottom: 0; left: 0; right: 0; width: 100%; height: 100%; opacity: 0; }
.overlay-effect .overlay a.expand { left: 0; right: 0; bottom: 47%; margin: 0 auto -30px auto; }
.overlay-effect .img.hover .overlay { height: 100%; opacity: 1; }
.img img { width: -webkit-calc(100%) !important; width: calc(100%) !important; -webkit-transition: opacity 0.35s, -webkit-transform 0.35s; transition: opacity 0.35s, transform 0.35s; -moz-transition: opacity 0.35s, -moz-transform 0.35s; -o-transition: opacity 0.35s, -o-transform 0.35s;}
.img:hover img { -webkit-transform: scale(1.1); transform: scale(1.1); -ms-transform: scale(1.1); }
/* * Corrects overflow displayed oddly in IE 9. */
svg:not(:root) { overflow: hidden; }
/* ==========================================================================
Figures
========================================================================== */
/* * Addresses margin not present in IE 6/7/8/9, Safari 5, and Opera 11. */

figure { margin: 0; }
/* ==========================================================================
Forms
========================================================================== */
/* * Corrects margin displayed oddly in IE 6/7. */

form { margin: 0; }
/* * Define consistent border, margin, and padding. */
fieldset { border: 1px solid #c0c0c0; margin: 0 2px; padding: 0.35em 0.625em 0.75em; }
/* * 1. Corrects color not being inherited in IE 6/7/8/9.
* 2. Corrects text not wrapping in Firefox 3.
* 3. Corrects alignment displayed oddly in IE 6/7. */
legend { border: 0; /* 1 */
	padding: 0; white-space: normal; /* 2 */
*margin-left: -7px; /* 3 */ }
/* * 1. Corrects font size not being inherited in all browsers.
* 2. Addresses margins set differently in IE 6/7, Firefox 3+, Safari 5, *    and Chrome.
* 3. Improves appearance and consistency in all browsers. */
button, input, select, textarea { font-size: 100%; /* 1 */
	margin: 0; /* 2 */
	vertical-align: baseline; /* 3 */
*vertical-align: middle; /* 3 */ }
/* * Addresses Firefox 3+ setting `line-height` on `input` using ` !important` in
* the UA stylesheet. */
button, input { line-height: normal; }
/* * 1. Avoid the WebKit bug in Android 4.0.* where (2) destroys native `audio`
*    and `video` controls.
* 2. Corrects inability to style clickable `input` types in iOS.
* 3. Improves usability and consistency of cursor style between image-type
*    `input` and others.
* 4. Removes inner spacing in IE 7 without affecting normal text inputs.
*    Known issue:inner spacing remains in IE 6. */
button, html input[type="button"], /* 1 */
input[type="reset"], input[type="submit"] { -webkit-appearance: button; /* 2 */
	cursor: pointer; /* 3 */
*overflow: visible; /* 4 */ }
/* * Re-set default cursor for disabled elements. */
button[disabled], input[disabled] { cursor: default; }
/* * 1. Addresses box sizing set to content-box in IE 8/9.
* 2. Removes excess padding in IE 8/9.
* 3. Removes excess padding in IE 7.
*    Known issue:excess padding remains in IE 6. */
input[type="checkbox"], input[type="radio"] { box-sizing: border-box; /* 1 */
	padding: 0; /* 2 */
	*height: 13px; /* 3 */
*width: 13px; /* 3 */ }
/* * 1. Addresses `appearance` set to `searchfield` in Safari 5 and Chrome.
* 2. Addresses `box-sizing` set to `border-box` in Safari 5 and Chrome
*    (include `-moz` to future-proof). */
input[type="search"] { -webkit-appearance: textfield; /* 1 */
	-moz-box-sizing: content-box; -webkit-box-sizing: content-box; /* 2 */
box-sizing: content-box; }
/* * Removes inner padding and search cancel button in Safari 5 and Chrome
* on OS X. */
input[type="search"]::-webkit-search-cancel-button, input[type="search"]::-webkit-search-decoration { -webkit-appearance: none; }
/* * Removes inner padding and border in Firefox 3+. */
button::-moz-focus-inner, input::-moz-focus-inner { border: 0; padding: 0; }
/* * 1. Removes default vertical scrollbar in IE 6/7/8/9.
* 2. Improves readability and alignment in all browsers. */
textarea { overflow: auto; /* 1 */
vertical-align: top; /* 2 */ }

/* ==========================================================================
Footer
========================================================================== */
footer a { color: #a1a9b0; }
footer { background-color: #242830; padding: 40px 0 30px; }
footer p { color: #fff; }
ul.legals { list-style: none; color: #fff; line-height: 25px; }
ul.legals li { display: inline-block; }
ul.legals li::after { content: " |"; }
ul.legals li:last-child::after { content: ""; }
.legals { text-align: left; }
.credit { text-align: right; }


p { color: #8c9398; word-spacing: 1px; line-height: 25px; font-size: 14px; }
h2 { font-size: 15px; color: #353535; text-transform: uppercase; font-weight: bold; }



.contact-details { margin: 70px 0; }
.contact-details h2 { font-size: 20px; }
.contact-details h2 span { display: block; color: #e37a84; text-transform: uppercase; letter-spacing: 1px; font-size: 14px; margin-top: 15px; }
.contact-wrap { position: relative; } 