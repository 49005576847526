@import "/home/coral/craneberry/assets/css/variables/colors.scss";

@import 'foundation.min';
@import 'basic';

html {
  scroll-behavior: smooth;
}


$hero-full-screen-bg: url('/assets/img/hero-bg-comp.jpg') center center no-repeat;
$hero-full-screen-bg-size: cover;
$hero-full-screen-text-color: $white;

.hero-full-screen {
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  background: $hero-full-screen-bg;
  background-size: $hero-full-screen-bg-size;

  .middle-content-section {
    text-align: center;
    color: $hero-full-screen-text-color;
  }

  .cb-logo {
  	max-width: 5vw;
  }

  .top-content-section {
    width: 100%;
  }



  .bottom-content-section {
    padding: 1rem;

    svg {
      height: rem-calc(60);
      width: rem-calc(60);
      fill: $hero-full-screen-text-color;
    }

    .hb2 {
      margin-top: 10px;
    }
  }

  // optional demo styles
  .top-bar {
    background: rgba(0,0,0,.4);
    box-shadow: 0 1px 4px rgba(0, 0, 0, 0.3);
    padding-left: 10vw;
    padding-right: 10vw;

    .cb {
      color: white;
      font-weight: bold;

      font-size: 18px;
    }

    .menu {
      background: transparent;
    }

    .menu-text {
      color: $hero-full-screen-text-color;
    }

    .menu li {
      display: flex;
      align-items: center;
    }

    .menu a {
      color: $hero-full-screen-text-color;
      font-weight: bold;
    }

    .menu  a:hover {
      color: #40a0ff;
    }
  }
}


.intro {
  padding-top: 5vw;
  padding-bottom: 5vw;
  overflow: hidden;
}

.work {
  padding-top: 4vw;
  padding-bottom: 4vw;
}

.contact {
  padding-top: 4vw;
  padding-bottom: 4vw;

  .ccd {
    padding-bottom: 4vw;
  }
}

.headline {
  color:#1b75bc;
  font-size: 30px;
  line-height: 1;
}

.contact {
  padding-bottom: 70px;
}

.dark-bg { background: #f3f5f8; }
.light-bg { background-color: #fff; }